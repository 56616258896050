<template>
    <div id="app" class="p-3">
        <div>
            <label for="Input1" class="form-label">输入你要问的问题</label>
            <textarea v-model="content" class="form-control" id="Input1" placeholder="输入问题" rows="5"></textarea>
        </div>
        <div class="text-center my-3">
            <button type="button" class="btn btn-outline-primary btn-sm m-1" @click="handleClick(1)">公开提问</button>
            <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="handleClick(0)">悄悄提问</button>
        </div>
        <div v-show="alertText" class="alert alert-primary" role="alert">
            {{ alertText }}
        </div>
        <div v-for="(item, index) in allData" :key="`all-${index}`">
            <div>Q{{ item.id }}:{{ item.content }}</div>
        </div>
        <div v-if="allData.length" class="p-3">-</div>
        <div v-for="(item, index) in currentData" :key="index">
            <div>Q{{ index + 1 }}:{{ item.content }}</div>
            <div v-if="item.public===0">A:{{ item.response || '暂无' }}</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'App',
    data() {
        return {content: '', alertText: '', currentData: [], allData: []};
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            if (location.search.includes('03-07')) {
                axios.get('/api/list')
                    .then(({data}) => {
                        this.allData = data;
                    })
            }
            axios.get('/api/message')
                .then(({data}) => {
                    this.currentData = data;
                })
        },
        handleClick(type) {
            axios.post('/api/message', {public: type, content: this.content})
                .then(() => {
                    this.loadData();
                })
                .catch((error) => {
                    if (error.response && error.response.data) {
                        this.alertText = error.response.data.message || error.message;
                        setTimeout(() => {
                            this.alertText = ''
                        }, 5000);
                    }
                })
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
